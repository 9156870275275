<template>
    <div>
        <h1>{{ title }}</h1>
        <div class="flex w-full justify-center">
            <img
                src="@/assets/images/maintenance_.jpeg"
                alt="Maintenance"
                class="maintenance-image"
            />
        </div>
        <p>We apologize for the inconvenience, but our site is currently undergoing maintenance.</p>
        <p>Please check back later.</p>
    </div>
</template>

<script>
import {ref} from '@vue/composition-api'

export default {
    name: 'MaintenanceView',
    setup() {
        const title = ref('Site Maintenance')
        return {
            title
            // Add any setup code here
        }
    }
}
</script>

<style>
body {
    font-family: Arial, sans-serif;
    /* background-color: #f5f5f5; */
    text-align: center;
    padding: 50px;
}
h1 {
    font-size: 24px;
    margin-bottom: 20px;
}
p {
    font-size: 16px;
    color: #666;
}
.maintenance-image {
    max-width: 100%;
    height: auto;
}
</style>
